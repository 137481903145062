import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import http from '@/plugins/axios'
import { NotificationEmail } from '@/api/interfaces/notificationemail'
import { TrainingSession } from '@/api/interfaces/trainingsession'
import { LogisticSession } from '@/api/interfaces/logisticSession'
import { TrainingType } from '@/api/interfaces/trainingtype'
import { EmailDocument } from '@/api/enums/emailDocument'
import { EmailSessionDocumentType } from '@/api/enums/emailSessionDocumentType'
import { Brand } from '@/api/enums/brand'
import { NeedTraining } from '@/api/interfaces/needtraining'

export interface NotificationEmailState {
  list: Array<NotificationEmail>
  loading: boolean,
}

@Module({ name: 'notificationEmail', namespaced: true })
class NotificationEmailModule extends VuexModule implements NotificationEmailState {
  public list: Array<NotificationEmail> = [];
  public loading = false;

  @Mutation
  public setList (list: Array<NotificationEmail>): void {
    this.list = list
  }

  @Mutation
  public setLoading (): void {
    this.loading = !this.loading
  }

  @Action({ rawError: true })
  public async load (): Promise<Array<NotificationEmail>> {
    this.context.commit('setLoading')
    const response = await http.get('/api/notification_emails')
    this.context.commit('setList', response.data['hydra:member'])
    this.context.commit('setList', response.data['hydra:member'])
    this.context.commit('setLoading')
    return response.data['hydra:member'] as Array<NotificationEmail>
  }

  @Action({ rawError: true })
  public async getNotificationEmailByNeedTraining (session: {
    needTraining: NeedTraining,
    emailDocument: EmailDocument,
    emailSendingType: EmailSessionDocumentType,
  }): Promise<NotificationEmail> {
    const needTraining = session.needTraining
    const emailDocument = session.emailDocument
    const emailSendingType = session.emailSendingType
    let brandVehicle = null
    if (
      needTraining.trainingType &&
      (needTraining.trainingType as TrainingType)?.specificToyota
    ) {
      const brand = needTraining.carBrand?.brand.toUpperCase()
      if (brand in Brand) {
        brandVehicle = brand.toLowerCase()
      }
    }

    return await this.getNotificationEmailByCode(
      emailDocument + '_' + emailSendingType + (brandVehicle ? '_' + brandVehicle : ''),
    ) as NotificationEmail
  }

  @Action({ rawError: true })
  public async getNotificationEmailByTrainingSession (session: {
    trainingSession: TrainingSession,
    logisticSession: LogisticSession,
    emailDocument: EmailDocument,
    emailSendingType: EmailSessionDocumentType,
  }): Promise<NotificationEmail> {
    const trainingSession = session.trainingSession
    const logisticSession = session.logisticSession
    const emailDocument = session.emailDocument
    const emailSendingType = session.emailSendingType
    let brandVehicle = null
    if (
      trainingSession.trainingType &&
      (trainingSession.trainingType as TrainingType)?.specificToyota
    ) {
      for (const vehicle of logisticSession.vehicles) {
        const brand = vehicle.carBrand?.brand.toUpperCase()
        if (brand in Brand) {
          brandVehicle = brand.toLowerCase()
          break
        }
      }
    }

    return await this.getNotificationEmailByCode(
      emailDocument + '_' + emailSendingType + (brandVehicle ? '_' + brandVehicle : ''),
    ) as NotificationEmail
  }

  @Action({ rawError: true })
  private getNotificationEmailByCode (code: string): NotificationEmail | null {
    return this.list.find((item: NotificationEmail) => item.code === code) || null
  }
}

export default NotificationEmailModule
