/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import { Company } from '@/api/interfaces/company'
import http from '@/plugins/axios'
import {
  constructUrlQueryString,
  QueryParams,
} from '@/store/api-plateform-utils'

export interface CompanyState {
  list: Array<Company>
  totalItems: number
  loading: boolean
  businessIntroducerToyota: Company
}

function newCompany (): Company {
  return {
    '@id': '',
    id: 0,
    name: '',
    siret: '',
    businessIntroducer: false,
  }
}

@Module({ name: 'Company', namespaced: true })
class CompanyModule extends VuexModule implements CompanyState {
  public list: Array<Company> = []
  public totalItems = 0
  public loading = false
  public businessIntroducerToyota = newCompany()

  @Mutation
  public setList (list: Array<Company>): void {
    this.list = list
  }

  @Mutation
  public setBusinessIntroducerToyota (company: Company): void {
    this.businessIntroducerToyota = company
  }

  @Mutation
  public addCompany (company: Company): void {
    this.list.push(company)
  }

  @Mutation
  public setTotalItems (total: number): void {
    this.totalItems = total
  }

  @Mutation
  public toggleLoading (): void {
    this.loading = !this.loading
  }

  @Action({ rawError: true })
  public async save (name: string): Promise<Company> {
    const companyObject = newCompany()
    companyObject.name = name
    const response = await http.post('/api/companies', companyObject)
    this.context.commit('addCompany', response.data)
    return response.data as Company
  }

  @Action({ rawError: true })
  public async load (queryParams: QueryParams): Promise<Array<Company>> {
    this.context.commit('toggleLoading')
    const option = constructUrlQueryString(queryParams)
    const response = await http.get(`/api/companies${option}`)
    this.context.commit('setList', response.data['hydra:member'])
    this.context.commit('setTotalItems', response.data['hydra:totalItems'])
    this.context.commit('toggleLoading')
    return response.data['hydra:member'] as Array<Company>
  }

  @Action({ rawError: true })
  public async loadBusinessIntroducerToyota (): Promise<Company> {
    this.context.commit('toggleLoading')
    const response = await http.get('/api/companies?name=Toyota&businessIntroducer=1')
    this.context.commit('setBusinessIntroducerToyota', response.data['hydra:member'][0])
    this.context.commit('toggleLoading')
    return response.data['hydra:member'][0] as Company
  }

  @Action({ rawError: true })
  public async loadByID (id: string): Promise<Company> {
    const response = await http.get(`/api/companies/${id}`)
    return response.data as unknown as Company
  }
}

export default CompanyModule
