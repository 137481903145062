/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import { TrainingLocation } from '@/api/interfaces/traininglocation'
import http from '@/plugins/axios'
import { getField, updateField } from 'vuex-map-fields'
import { cloneObjectReplaceSubObjectByIRI } from '@/store/api-plateform-utils'

export interface TrainingLocationFormState {
  trainingLocation: TrainingLocation
  isChanged: boolean,
  isSubmitted: boolean,
}

export function newTrainingLocation (): TrainingLocation {
  return {
      '@id': '',
      name: '',
      address: '',
      longitude: undefined,
      latitude: undefined,
      zipCode: '',
      city: '',
      area: null,
      webSite: '',
      openingTime: '',
      type: '',
      maximumCapacity: undefined,
      price: undefined,
      trainingType: [],
      commentary: '',
      civilityContact: '',
      lastNameContact: '',
      firstNameContact: '',
      phoneNumberContact: '',
      secondPhoneNumberContact: '',
      emailContact: '',
  }
}

@Module({ name: 'trainingLocationForm', namespaced: true })
class TrainingLocationFormModule extends VuexModule implements TrainingLocationFormState {
  public trainingLocation: TrainingLocation = newTrainingLocation();
  public isChanged = false;
  public isSubmitted = false;

  @Mutation
  public setTrainingLocation (trainingLocation: TrainingLocation): void {
    this.trainingLocation = trainingLocation
    this.isChanged = false
  }

  @Mutation
  public setIsSubmitted (submitted: boolean): void {
    this.isSubmitted = submitted
  }

  @Mutation
  updateField (options: {path: string; value: unknown}) {
    this.isChanged = true
    return updateField(this, options)
  }

  get getField () {
    return getField(this)
  }

  @Action({ rawError: true })
  public async loadById (id: number): Promise<TrainingLocation> {
    const response = await http.get(`/api/training_locations/${id}`)
    const trainingLocation = response.data as TrainingLocation
    this.context.commit('setTrainingLocation', trainingLocation)
    return trainingLocation
  }

  @Action({ rawError: true })
  public async loadByIri (iri: string): Promise<TrainingLocation> {
    const response = await http.get(iri)
    const trainingLocation = response.data as TrainingLocation
    this.context.commit('setTrainingLocation', trainingLocation)
    return trainingLocation
  }

  @Action({ rawError: true })
  public async save (): Promise<TrainingLocation> {
    this.context.commit('setIsSubmitted', true)
    try {
      const trainingLocationToPost: TrainingLocation = cloneObjectReplaceSubObjectByIRI(this.trainingLocation)
      if (trainingLocationToPost['@id']) {
        const response = await http.put(trainingLocationToPost['@id'], trainingLocationToPost)
        this.context.commit('setTrainingLocation', response.data)
      } else {
        const response = await http.post('/api/training_locations', trainingLocationToPost)
        this.context.commit('setTrainingLocation', response.data)
      }
    } finally {
      this.context.commit('setIsSubmitted', false)
    }

    return this.trainingLocation
  }

  @Action({ rawError: true })
  public async duplicate (): Promise<TrainingLocation> {
    this.context.commit('setIsSubmitted', true)
    try {
      delete this.trainingLocation['@id']
      delete this.trainingLocation.id
      const trainingLocationToPost: TrainingLocation = cloneObjectReplaceSubObjectByIRI(this.trainingLocation)
      const response = await http.post('/api/training_locations', trainingLocationToPost)
      this.context.commit('setTrainingLocation', response.data)
    } finally {
      this.context.commit('setIsSubmitted', false)
    }

    return this.trainingLocation
  }

  @Action({ rawError: true })
  public async reset (): Promise<TrainingLocation> {
    const trainingLocation:TrainingLocation = newTrainingLocation()
    this.context.commit('setTrainingLocation', trainingLocation)
    this.context.commit('setIsSubmitted', false)
    return trainingLocation
  }
}

export default TrainingLocationFormModule
