import store from '@/store'
import router from '@/router'
import { groupSuperAdmin } from '@/config-constantes'

export function userHasRoles (routeRoles: string[]): boolean {
  const userRoles = store.getters['user/roles']
  if (!userRoles || !routeRoles) {
    return false
  }
  const roles = userRoles.filter((userRole: string) => routeRoles.includes(userRole))

  if (!roles) {
    return false
  }
  return roles.length !== 0
}

export function userHasRole (role: string): boolean {
  const userRoles = store.getters['user/roles']
  if (!userRoles) {
    return false
  }
  return userRoles.includes(role)
}

export function userCanAccess (routeName: string): boolean {
  const routeRoles = router.resolve({ name: routeName }).resolved.meta?.roles
  if (!routeRoles) {
    return false
  }

  return userHasRoles(routeRoles)
}

export function userHasGroup (group: string): boolean {
  const userGroups = store.getters['user/groups']
  if (!userGroups) {
    return false
  }
  return userGroups.includes(group)
}

export function userCanChangeRole (role: string): boolean {
  return userHasGroup(groupSuperAdmin) || userHasRole(role)
}

export function isReadonly (): boolean {
  const userRoles = store.getters['user/roles']
  const route = router.currentRoute
  if (!userRoles) {
    return false
  }
  const roles = userRoles.filter((userRole: string) => route.meta.roles.includes(userRole))
  if (!roles) {
    return false
  }
  for (const role of roles) {
    if (role.indexOf('_WRITE_') !== -1) {
      return false
    }
  }

  return true
}
