/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { TrainingType } from '@/api/interfaces/trainingtype'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { getField, updateField } from 'vuex-map-fields'
import { cloneObjectReplaceSubObjectByIRI } from '@/store/api-plateform-utils'
import http from '@/plugins/axios'

export interface TrainingTypeFormState {
  trainingType: TrainingType
  isChanged: boolean
  isSubmitted: boolean
}

export function newTrainingType (): TrainingType {
  return {
    '@id': '',
    name: '',
    titleConvention: '',
    titleAttestation: '',
    codeTeachingResourcesConvention: '',
    specificToyota: false,
    color: '',
    duration: '',
    durationDays: undefined,
    timeSlots: [],
    pictogram: '',
    teachingAids: [],
    trainingPlanningSharepointLink: '',
    isSpecificTrappesSpe: false,
    isActive: true,
  }
}

@Module({ name: 'trainingTypeForm', namespaced: true })
class TrainingTypeFormModule extends VuexModule implements TrainingTypeFormState {
  public trainingType: TrainingType = newTrainingType();
  public isChanged = false;
  public isSubmitted = false;

  @Mutation
  public setTrainingType (trainingType: TrainingType): void {
    this.trainingType = trainingType
    this.isChanged = false
  }

  @Mutation
  public setIsSubmitted (submitted: boolean):void {
    this.isSubmitted = submitted
  }

  @Mutation setIsChanged (isChanged: boolean): void {
    this.isChanged = isChanged
  }

  @Mutation
  updateField (options: {path: string; value: unknown}) {
    this.isChanged = true
    return updateField(this, options)
  }

  get getField () {
    return getField(this)
  }

  get getTrainingType (): TrainingType {
    return this.trainingType
  }

  @Action({ rawError: true })
  public async loadById (id: number): Promise<TrainingType> {
    const response = await http.get(`/api/training_types/${id}`)
    const trainingType = response.data as TrainingType
    this.context.commit('setTrainingType', trainingType)
    return trainingType
  }

  @Action({ rawError: true })
  public async save (): Promise<TrainingType> {
    this.context.commit('setIsSubmitted', true)
    try {
      const trainingTypeToPost: TrainingType = cloneObjectReplaceSubObjectByIRI(this.trainingType)
      if (trainingTypeToPost['@id']) {
        const response = await http.put(trainingTypeToPost['@id'], trainingTypeToPost)
        this.context.commit('setTrainingType', response.data)
      } else {
        const response = await http.post('/api/training_types', trainingTypeToPost)
        this.context.commit('setTrainingType', response.data)
      }
    } finally {
      this.context.commit('setIsSubmitted', false)
    }
     return this.trainingType
  }

  @Action({ rawError: true })
  public async reset (): Promise<TrainingType> {
    const trainingType: TrainingType = newTrainingType()
    this.context.commit('setTrainingType', trainingType)
    this.context.commit('setIsSubmitted', false)
    return trainingType
  }
}

export default TrainingTypeFormModule
