import { Brand } from '@/api/enums/brand'
import { TrainingSessionStatus } from '@/api/enums/trainingSessionStatus'

export const trainingLocationTypeCarDealer = 'En concession'
export const trainingLocationTypeSubRegion = 'Sous-Région'

export const trainingLocationRencontresCode = 'rencontres-concessions'

export const trainingLocationTypes = [
  'Circuit',
  'Salle',
  'Chez le client',
  'Driver Program 1h30',
  trainingLocationTypeCarDealer,
  'En entreprise',
  'Lieu d\'évènement',
  trainingLocationTypeSubRegion,
  'Autre',
]

export const trainingSessionStatuses = [
  {
    value: TrainingSessionStatus.STATUS_OPTIONAL,
    icon: 'mdi-progress-question',
  },
  {
    value: TrainingSessionStatus.STATUS_IN_PROGRESS,
    icon: 'mdi-progress-check',
  },
  {
    value: TrainingSessionStatus.STATUS_CHECKED,
    icon: 'mdi-check-circle',
  },
  {
    value: TrainingSessionStatus.STATUS_DONE,
    icon: 'mdi-thumb-up',
  },
  {
    value: TrainingSessionStatus.STATUS_BILLED,
    icon: 'mdi-cash-check',
  },
  {
    value: TrainingSessionStatus.STATUS_CANCELED,
    icon: 'mdi-close-circle',
  },
]

export const carDealerRegions = [
  'E1',
  'E2',
  'E3',
  'E4',
  'E5',
  'E6',
  'E7',
  'E8',
]

export const needTrainingStatusesCodesToPlanifie = [
  'a_contacter',
  'ok_attente_validation_date',
  'ok_attente_coord_conducteur',
  'ok_attente_de_relance',
  'ok_attente_livraison',
  'absence_reponse',
  'absent',
]

export const roles = [
  { role: 'ROLE_WRITE_LOGISTIC_SESSION' },
  { role: 'ROLE_READ_LOGISTIC_SESSION' },
  { role: 'ROLE_WRITE_TRAINING_SESSION' },
  { role: 'ROLE_READ_TRAINING_SESSION' },
  { role: 'ROLE_READ_PLANNING' },
  { role: 'ROLE_READ_TRAINEE_IMPORT' },
  { role: 'ROLE_WRITE_TRAINEE_IMPORT' },
  { role: 'ROLE_READ_TRAINEE' },
  { role: 'ROLE_WRITE_TRAINEE' },
  { role: 'ROLE_WRITE_NEED_TRAINING' },
  { role: 'ROLE_READ_VEHICULE' },
  { role: 'ROLE_WRITE_VEHICULE' },
  { role: 'ROLE_DELETE_VEHICULE' },
  { role: 'ROLE_WRITE_TEACHING_TOOL' },
  { role: 'ROLE_READ_TEACHING_TOOL' },
  { role: 'ROLE_DELETE_TEACHING_TOOL' },
  { role: 'ROLE_READ_COMPANY' },
  { role: 'ROLE_WRITE_COMPANY' },
  { role: 'ROLE_READ_TRAINING_LOCATION' },
  { role: 'ROLE_WRITE_TRAINING_LOCATION' },
  { role: 'ROLE_DELETE_TRAINING_LOCATION' },
  { role: 'ROLE_READ_TRAINER' },
  { role: 'ROLE_WRITE_TRAINER' },
  { role: 'ROLE_READ_USER_GROUP' },
  { role: 'ROLE_WRITE_USER_GROUP' },
  { role: 'ROLE_READ_USER' },
  { role: 'ROLE_WRITE_USER' },
  { role: 'ROLE_EXPORT_TRAINEE' },
  { role: 'ROLE_DELETE_TRAINEE' },
  { role: 'ROLE_READ_BOOKING' },
  { role: 'ROLE_WRITE_BOOKING' },
  { role: 'ROLE_READ_TRAINING_TYPE' },
  { role: 'ROLE_WRITE_TRAINING_TYPE' },
  { role: 'ROLE_WRITE_EVENT_DAY' },
  { role: 'ROLE_DELETE_EVENT_DAY' },
  { role: 'ROLE_SESSION_EMAIL_PREPARATION' },
  { role: 'ROLE_TRAINER_REGION_MANAGER' },
  { role: 'ROLE_FORCE_CHANGE_NEED_TRAINING_STATUS' },
  { role: 'ROLE_READ_CAR_DEALER' },
  { role: 'ROLE_WRITE_CAR_DEALER' },
  { role: 'ROLE_READ_CAR_MODEL' },
  { role: 'ROLE_WRITE_CAR_MODEL' },
  { role: 'ROLE_READ_NOTIFICATION_EMAIL' },
  { role: 'ROLE_WRITE_NOTIFICATION_EMAIL' },
  { role: 'ROLE_READ_CONFIGURATION' },
  { role: 'ROLE_WRITE_CONFIGURATION' },
]

export const codeTeachingResourcesConvention = [
  'RR',
  'EC',
]

export const driverProgram = 'driver-program-1h30'
export const hybridAcademyTrainingTypes = ['hybrid-academy', driverProgram]

export const brands = Object.values(Brand)

// Back enum: CompanyAccountType.php
export const accountTypes = [
  'small_accounts',
  'average_accounts',
  'big_accounts',
  'learning',
]

export const groupSuperAdmin = 'Super Admin'
export const trainingTypePictograms = [
  {
    value: 'mdi-car',
    icon: 'mdi-car',
  },
  {
    value: 'mdi-sim-alert',
    icon: 'mdi-sim-alert',
  },
  {
    value: 'mdi-moon-waning-crescent',
    icon: 'mdi-moon-waning-crescent',
  },
  {
    value: 'mdi-car-clock',
    icon: 'mdi-car-clock',
  },
  {
    value: 'mdi-car-wrench',
    icon: 'mdi-car-wrench',
  },
  {
    value: 'mdi-car-traction-control',
    icon: 'mdi-car-traction-control',
  },
  {
    value: 'mdi-car-electric',
    icon: 'mdi-car-electric',
  },
  {
    value: 'mdi-car-wash',
    icon: 'mdi-car-wash',
  },
  {
    value: 'mdi-battery-charging',
    icon: 'mdi-battery-charging',
  },
  {
    value: 'mdi-car-brake-alert',
    icon: 'mdi-car-brake-alert',
  },
  {
    value: 'mdi-cogs',
    icon: 'mdi-cogs',
  },
  {
    value: 'mdi-clock',
    icon: 'mdi-clock',
  },
  {
    value: 'mdi-wheelchair',
    icon: 'mdi-wheelchair',
  },
  {
    value: 'mdi-leaf',
    icon: 'mdi-leaf',
  },
  {
    value: 'mdi-emoticon-happy',
    icon: 'mdi-emoticon-happy',
  },
  {
    value: 'mdi-sun-snowflake',
    icon: 'mdi-sun-snowflake',
  },
  {
    value: 'mdi-snowflake-alert',
    icon: 'mdi-snowflake-alert',
  },
  {
    value: 'mdi-snowflake',
    icon: 'mdi-snowflake',
  },
  {
    value: 'mdi-directions',
    icon: 'mdi-directions',
  },
  {
    value: 'mdi-recycle',
    icon: 'mdi-recycle',
  },
  {
    value: 'mdi-account-group',
    icon: 'mdi-account-group',
  },
  {
    value: 'mdi-shield-alert',
    icon: 'mdi-shield-alert',
  },
  {
    value: 'mdi-weather-lightning',
    icon: 'mdi-weather-lightning',
  },
  {
    value: 'mdi-flag',
    icon: 'mdi-flag',
  },
  {
    value: 'mdi-alert',
    icon: 'mdi-alert',
  },
  {
    value: 'mdi-account',
    icon: 'mdi-account',
  },
  {
    value: 'mdi-account-credit-card',
    icon: 'mdi-account-credit-card',
  },
  {
    value: 'mdi-terrain',
    icon: 'mdi-terrain',
  },
  {
    value: 'mdi-camera-timer',
    icon: 'mdi-camera-timer',
  },
  {
    value: 'mdi-devices',
    icon: 'mdi-devices',
  },
  {
    value: 'mdi-calendar-month',
    icon: 'mdi-calendar-month',
  },
  {
    value: 'mdi-weather-pouring',
    icon: 'mdi-weather-pouring',
  },
  {
    value: 'mdi-paperclip',
    icon: 'mdi-paperclip',
  },
  {
    value: 'mdi-trophy-variant',
    icon: 'mdi-trophy-variant',
  },
  {
    value: 'mdi-book-open-page-variant-outline',
    icon: 'mdi-book-open-page-variant-outline',
  },
  {
    value: 'mdi-tools',
    icon: 'mdi-tools',
  },
  {
    value: 'mdi-hand-back-right',
    icon: 'mdi-hand-back-right',
  },
  {
    value: 'mdi-information-variant-circle',
    icon: 'mdi-information-variant-circle',
  },
  {
    value: 'mdi-timer-sand-complete',
    icon: 'mdi-timer-sand-complete',
  },
  {
    value: 'mdi-truck',
    icon: 'mdi-truck',
  },
  {
    value: 'mdi-city',
    icon: 'mdi-city',
  },
  {
    value: 'mdi-alert-decagram',
    icon: 'mdi-alert-decagram',
  },
  {
    value: 'mdi-wifi-strength-4-alert',
    icon: 'mdi-wifi-strength-4-alert',
  },
  {
    value: 'mdi-brain',
    icon: 'mdi-brain',
  },
  {
    value: 'mdi-map-marker',
    icon: 'mdi-map-marker',
  },
  {
    value: 'mdi-road-variant',
    icon: 'mdi-road-variant',
  },
  {
    value: 'mdi-sign-direction',
    icon: 'mdi-sign-direction',
  },
  {
    value: 'mdi-swap-horizontal-variant',
    icon: 'mdi-swap-horizontal-variant',
  },
  {
    value: 'mdi-traffic-light',
    icon: 'mdi-traffic-light',
  },
  {
    value: 'mdi-timelapse',
    icon: 'mdi-timelapse',
  },
  {
    value: 'mdi-star',
    icon: 'mdi-star',
  },
  {
    value: 'mdi-forest',
    icon: 'mdi-forest',
  },
  {
    value: 'mdi-puzzle',
    icon: 'mdi-puzzle',
  },
  {
    value: 'mdi-laptop',
    icon: 'mdi-laptop',
  },
  {
    value: 'mdi-call-merge',
    icon: 'mdi-call-merge',
  },
  {
    value: 'mdi-chart-pie',
    icon: 'mdi-chart-pie',
  },
  {
    value: 'mdi-view-carousel',
    icon: 'mdi-view-carousel',
  },
]
