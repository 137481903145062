/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import { Manager } from '@/api/interfaces/manager'
import http from '@/plugins/axios'
import { Company } from '@/api/interfaces/company'

export interface ManagerListState {
  list: Array<Manager>
  totalItems: number
}

export function newManager (): Manager {
  return {
    '@id': '',
    profession: '',
    civility: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    secondPhoneNumber: '',
    email: '',
    company: '',
    main: false,
  }
}

@Module({ name: 'managerList', namespaced: true })
class ManagerListModule extends VuexModule implements ManagerListState {
  public list: Array<Manager> = []
  public totalItems = 0

  @Mutation
  public setList (list: Array<Manager>): void {
    this.list = list
  }

  @Mutation
  public setTotalItems (total: number): void {
    this.totalItems = total
  }

  @Mutation
  public addManager (manager: Manager): void {
    this.list.push(manager)
    this.totalItems++
  }

  @Mutation
  public replaceManager (manager: Manager): void {
    const position = this.list.findIndex(
      element => element['@id'] === manager['@id'],
    )
    if (this.list[position]) {
      this.list[position] = manager
    }
  }

  @Action({ rawError: true })
  public reset (): void {
    this.context.commit('setList', [])
    this.context.commit('setTotalItems', 0)
  }

  @Action({ rawError: true })
  public async load (
    company: Company | Array<Company>,
  ): Promise<Array<Manager>> {
    let response
    if (Array.isArray(company)) {
      let companiesRequest = '?'
      company.map(function (company: Company) {
        if (company['@id']) {
          companiesRequest += 'company[]=' + company['@id'] + '&'
        }
      })
      companiesRequest = companiesRequest.slice(0, -1)

      response = await http.get('/api/managers' + companiesRequest)
    } else {
      response = await http.get(`/api/managers?company=${company.id}`)
    }
    this.context.commit('setList', response.data['hydra:member'])
    this.context.commit('setTotalItems', response.data['hydra:totalItems'])
    return response.data['hydra:member'] as Array<Manager>
  }

  @Action({ rawError: true })
  public async post (manager: Manager): Promise<Manager> {
    const response = await http.post('/api/managers', manager)
    this.context.commit('addManager', response.data)
    return response.data as Manager
  }

  @Action({ rawError: true })
  public async put (manager: Manager): Promise<Manager> {
    if (manager['@id']) {
      const response = await http.put(manager['@id'], manager)
      this.context.commit('replaceManager', response.data)
      return response.data as Manager
    }
    return this.post(manager)
  }
}

export default ManagerListModule
