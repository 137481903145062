import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import i18n from '@/i18n'
import '@/sass/overrides.sass'
import mergeIcon from '@/components/molecules/icons/mergeIcon.vue'

Vue.use(Vuetify)
const themeCommun = {
  options: { customProperties: true },
  primary: '#EC6958',
  accent: '#EC6958',
  link: '#18A0FB',
}
const dark = {
  ...themeCommun,
  backgroundColor: '#1D1D1E',
  secondary: '#214142',
  info: '#214142',
}
const light = {
  ...themeCommun,
  backgroundColor: '#f5f5f5',
  secondary: '#55a7aa',
  info: '#55a7aa',
}

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
  dark: false,
  theme: {
      dark,
      light,
      themes: {
        dark,
        light,
      },
    // TODO UNCOMMENT FOR PROD
    // options: {
    //   themeCache: {
    //     get: key => localStorage.getItem(key),
    //     set: (key, value) => localStorage.setItem(key, value),
    //   },
    // },
  },
  icons: {
    values: {
      custom: {
        // name of our custom icon
        component: mergeIcon, // our custom component
      },
    },
  },
})
