/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { getField, updateField } from 'vuex-map-fields'
import { cloneObjectReplaceSubObjectByIRI } from '@/store/api-plateform-utils'
import http from '@/plugins/axios'
import { NotificationEmail } from '@/api/interfaces/notificationemail'
import { EmailDocument } from '@/api/enums/emailDocument'
import { EmailSessionDocumentType } from '@/api/enums/emailSessionDocumentType'
import { NotificationEmailAttestationIndividual } from '@/enums/notificationEmailAttestationIndividual'
import { NotificationEmailAttestationCompany } from '@/enums/notificationEmailAttestationCompany'
import { NotificationEmailConvocationIndividual } from '@/enums/notificationEmailConvocationIndividual'
import { NotificationEmailConvocationCompany } from '@/enums/notificationEmailConvocationCompany'

export interface NotificationEmailFormState {
  notificationEmail: NotificationEmail
  variables: Array<string>
  isChanged: boolean
  isSubmitted: boolean
}

export function newNotificationEmail (): NotificationEmail {
  return {
    '@id': '',
    code: '',
    type: undefined,
    object: '',
    content: '',
  }
}

@Module({ name: 'notificationEmailForm', namespaced: true })
class NotificationEmailFormModule extends VuexModule implements NotificationEmailFormState {
  public notificationEmail: NotificationEmail = newNotificationEmail();
  public variables: Array<string> = [];
  public isChanged = false;
  public isSubmitted = false;

  @Mutation
  public setNotificationEmail (notificationEmail: NotificationEmail): void {
    this.notificationEmail = notificationEmail
    this.isChanged = false
  }

  @Mutation
  public setIsSubmitted (submitted: boolean):void {
    this.isSubmitted = submitted
  }

  @Mutation setIsChanged (isChanged: boolean): void {
    this.isChanged = isChanged
  }

  @Mutation setObjectAndContentVariables (): void {
    switch (this.notificationEmail.type) {
      case EmailDocument.ATTESTATION:
        this.notificationEmail?.code?.includes(EmailSessionDocumentType.INDIVIDUAL)
          ? this.variables = Object.values(NotificationEmailAttestationIndividual)
          : this.variables = Object.values(NotificationEmailAttestationCompany)
        break
      case EmailDocument.CONVOCATION:
        this.notificationEmail?.code?.includes(EmailSessionDocumentType.INDIVIDUAL)
          ? this.variables = Object.values(NotificationEmailConvocationIndividual)
          : this.variables = Object.values(NotificationEmailConvocationCompany)
        break
      default:
        this.variables = []
    }
  }

  @Mutation
  updateField (options: {path: string; value: unknown}) {
    this.isChanged = true
    return updateField(this, options)
  }

  get getField () {
    return getField(this)
  }

  get getNotificationEmail (): NotificationEmail {
    return this.notificationEmail
  }

  @Action({ rawError: true })
  public async loadById (id: number): Promise<NotificationEmail> {
    const response = await http.get(`/api/notification_emails/${id}`)
    const notificationEmail = response.data as NotificationEmail
    this.context.commit('setNotificationEmail', notificationEmail)
    this.context.commit('setObjectAndContentVariables')
    return notificationEmail
  }

  @Action({ rawError: true })
  public async save (): Promise<NotificationEmail> {
    this.context.commit('setIsSubmitted', true)
    try {
      const notificationEmail: NotificationEmail = cloneObjectReplaceSubObjectByIRI(this.notificationEmail)
      const response = await http.put(notificationEmail['@id'] as string, notificationEmail)
      this.context.commit('setNotificationEmail', response.data)
    } finally {
      this.context.commit('setIsSubmitted', false)
    }
     return this.notificationEmail
  }

  @Action({ rawError: true })
  public async reset (): Promise<NotificationEmail> {
    const notificationEmail: NotificationEmail = newNotificationEmail()
    this.context.commit('setNotificationEmail', notificationEmail)
    this.context.commit('setObjectAndContentVariables')
    this.context.commit('setIsSubmitted', false)
    return notificationEmail
  }
}

export default NotificationEmailFormModule
