/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import { Company } from '@/api/interfaces/company'
import http from '@/plugins/axios'
import { getField, updateField } from 'vuex-map-fields'
import { Manager } from '@/api/interfaces/manager'
import { managerListStore } from '@/store'
import { cloneObjectReplaceSubObjectByIRI } from '@/store/api-plateform-utils'

export interface CompanyFormState {
  company: Company,
  isChanged: boolean,
  isSubmitted: boolean,
}

function newCompany (): Company {
  return {
    '@id': '',
    name: '',
    nameComplementary: '',
    city: '',
    managers: [],
    address: '',
    clientType: 'Professionnels',
    area: null,
    type: '',
    zipCode: '',
    phoneNumber: '',
    secondPhoneNumber: '',
    email: '',
    website: '',
    siret: '',
    businessIntroducer: false,
    commentary: '',
  }
}

@Module({ name: 'companyForm', namespaced: true })
class CompanyFormModule extends VuexModule implements CompanyFormState {
  public company: Company = newCompany()
  public isChanged = false
  public isSubmitted = false

  @Mutation
  public setCompany (company: Company): void {
    this.company = company
    this.isChanged = false
  }

  @Mutation
  public setIsSubmitted (submited: boolean): void {
    this.isSubmitted = submited
  }

  @Mutation
  updateField (options: { path: string; value: unknown }) {
    this.isChanged = true
    return updateField(this, options)
  }

  get getField () {
    return getField(this)
  }

  @Action({ rawError: true })
  public async loadById (id: number): Promise<Company> {
    const response = await http.get(`/api/companies/${id}`)
    const company = response.data as Company
    this.context.commit('setCompany', company)
    return company
  }

  @Mutation
  public addManager (manager: Manager): void {
    if (!this.company.managers) {
      this.company.managers = []
    }
    this.company.managers.push(manager)
  }

  @Mutation
  public updateMainManager (managers: Array<Manager>): void {
    this.company.managers = managers
    this.isChanged = true
  }

  @Action({ rawError: true })
  public async save (): Promise<Company> {
    this.context.commit('setIsSubmitted', true)
    try {
      const company: Company = cloneObjectReplaceSubObjectByIRI(this.company)
      if (this.company['@id']) {
        const response = await http.put(this.company['@id'], company)
        this.context.commit('setCompany', response.data)
      } else {
        const response = await http.post('/api/companies', company)
        // save managers
        const iriCompany = response.data['@id']
        if (this.company.managers) {
          this.company.managers.forEach(async (manager: Manager) => {
            manager.company = iriCompany
            await managerListStore.post(manager)
          })
        }
        this.context.commit('setCompany', response.data)
      }
    } finally {
      this.context.commit('setIsSubmitted', false)
    }
    return this.company
  }

  @Action({ rawError: true })
  public async reset (): Promise<Company> {
    const company = newCompany()
    this.context.commit('setCompany', company)
    this.context.commit('setIsSubmitted', false)
    return company
  }

  @Action({ rawError: true })
  public async setMainManager (mainManager: Manager): Promise<Company> {
    if (mainManager?.id) {
      const response = await http.put(`${this.company['@id']}/set_main_manager`, { mainManagerId: mainManager.id })
      this.context.commit('updateMainManager', response.data.managers)
    } else {
      const managers = this.company?.managers
      if (managers) {
        managers.forEach((manager: Manager) => {
          manager.main = false
        })
      }
      mainManager.main = true
    }

    return this.company
  }
}

export default CompanyFormModule
