/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import { CarModel } from '@/api/interfaces/carmodel'
import http from '@/plugins/axios'
import { CarBrand } from '@/api/interfaces/carbrand'

export interface CarModeleListState {
  list: Array<CarModel>
  totalItems: number
}

export function newCarModel (): CarModel {
  return {
      '@id': '',
      model: '',
  }
}

@Module({ name: 'carModel', namespaced: true })
class CarModeleListModule extends VuexModule implements CarModeleListState {
  public list: Array<CarModel> = []
  public totalItems = 0

  @Mutation
  public setList (list: Array<CarModel>): void {
    this.list = list
  }

  @Mutation
  public addModel (model: CarModel): void {
    this.list.push(model)
    this.totalItems++
  }

  @Mutation
  public setTotalItems (total: number): void {
    this.totalItems = total
  }

  @Action({ rawError: true })
  public async load (carBrand: CarBrand): Promise<Array<CarModel>> {
    this.context.commit('setList', [])
    this.context.commit('setTotalItems', 0)
    const response = await http.get(`/api/car_models?pagination=false&carBrand=${carBrand.id}`)
    this.context.commit('setList', response.data['hydra:member'])
    this.context.commit('setTotalItems', response.data['hydra:totalItems'])
    return response.data['hydra:member'] as Array<CarModel>
  }

  @Action({ rawError: true })
  public async save (carModel: CarModel): Promise<CarModel> {
    const response = await http.post('/api/car_models', carModel)
    this.context.commit('addModel', response.data)
    return response.data as CarModel
  }
}

export default CarModeleListModule
