



























































import { defineComponent } from '@vue/composition-api'
import { format, formatISO, parse, parseISO, parseJSON } from 'date-fns'

export default defineComponent({
  name: 'DateField',

  props: {
    value: {
      type: String,
      default: () => null,
    },
    label: {
      type: String,
      default: 'Date',
    },
    locale: {
      type: String,
      default: 'fr',
    },
    textFieldClass: {
      type: String,
      default: '',
    },
    keepHour: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: String,
      default: 'required',
    },
    isClearable: {
      type: Boolean,
      default: false,
    },
    solo: {
      type: Boolean,
      default: false,
    },
    light: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: '',
    },
    max: {
      type: String,
      default: '',
    },
    min: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      modalPicker: false,
    }
  },
  computed: {
    dateFormated: {
      get (): string | null {
        return this.value ? format(parseJSON(this.value), 'dd/MM/yyyy') : null
      },
      set (value: string) {
        if (value) {
          this.$emit(
            'input',
            formatISO(parse(value, 'dd/MM/yyyy', parseJSON(this.value))),
          )
        } else {
          this.$emit('input', null)
        }
      },
    },

    dateIso: {
      get (): string | null {
        return this.value
          ? formatISO(parseJSON(this.value), { representation: 'date' })
          : null
      },
      set (value: string) {
        if (value) {
          let dateTimeValue = value
          if (this.keepHour && this.value) {
            dateTimeValue = value + this.value.slice(10)
          }
          this.$emit('input', formatISO(parseISO(dateTimeValue)))
        }
      },
    },
  },
})
