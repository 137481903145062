















































import { defineComponent } from '@vue/composition-api'
import { formatISO9075, parseJSON } from 'date-fns'

export default defineComponent({
  name: 'DateFieldFilter',
  components: {
    DateField: () => import('@/components/base/DateField.vue'),
  },
  data () {
    return {
      filterValue: '',
    }
  },
  props: {
    label: {
      type: String,
    },
    value: {
      type: String,
      default: '',
    },
  },
  created () {
    this.filterValue = this.value
  },
  methods: {
    filter () {
      this.$emit('day-with-no-hours', formatISO9075(parseJSON(this.filterValue)))
    },
    clear () {
      this.filterValue = ''
      this.$emit('day-with-no-hours', '')
    },
  },
})
