/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import { CarEngine } from '@/api/interfaces/carengine'
import { CarModel } from '@/api/interfaces/carmodel'
import http from '@/plugins/axios'
import { cloneObjectReplaceSubObjectByIRI } from '@/store/api-plateform-utils'

export interface CarEngineState {
  list: Array<CarEngine>
  totalItems: number
}

export function newCarEngine (): CarEngine {
  return {
      '@id': '',
      engine: '',
  }
}

@Module({ name: 'CarEngine', namespaced: true })
class CarEngineModule extends VuexModule implements CarEngineState {
  public list: Array<CarEngine> = []
  public totalItems = 0

  @Mutation
  public setList (list: Array<CarEngine>): void {
    this.list = list
  }

  @Mutation
  public addEngine (engine: CarEngine): void {
    this.list.push(engine)
    this.totalItems++
  }

  @Mutation
  public replaceCarEngine (carEngine: CarEngine): void {
    const position = this.list.findIndex(
      element => element['@id'] === carEngine['@id'],
    )
    if (this.list[position]) {
      this.list[position] = carEngine
    }
  }

  @Mutation
  public setTotalItems (total: number): void {
    this.totalItems = total
  }

  @Action({ rawError: true })
  public async load (carModel: CarModel): Promise<Array<CarEngine>> {
    const response = await http.get(`/api/car_engines?pagination=false&carModel=${carModel.id}`)
    this.context.commit('setList', response.data['hydra:member'])
    this.context.commit('setTotalItems', response.data['hydra:totalItems'])
    return response.data['hydra:member'] as Array<CarEngine>
  }

  @Action({ rawError: true })
  public async save (carEngine: CarEngine): Promise<CarEngine> {
    const carEngineToSave: CarEngine = cloneObjectReplaceSubObjectByIRI(carEngine)
    if (carEngineToSave['@id']) {
      const response = await http.put(carEngineToSave['@id'], carEngineToSave)
      carEngine = response.data
      this.context.commit('replaceCarEngine', carEngine)
    } else {
      const response = await http.post('/api/car_engines', carEngineToSave)
      carEngine = response.data
      this.context.commit('addEngine', carEngine)
    }
    return carEngine
  }
}

export default CarEngineModule
