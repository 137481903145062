import { parseJSON, format, addDays } from 'date-fns'

export function formatDate (jsonStringDate: string): string {
  if (jsonStringDate) {
    const date = parseJSON(jsonStringDate)
    return format(date, 'dd/MM/yyyy')
  }
  return ''
}

export function formatDateHour (jsonStringDate: string): string {
  if (jsonStringDate) {
    const date = parseJSON(jsonStringDate)
    return format(date, 'dd/MM/yyyy HH:mm')
  }
  return ''
}

export function formatHour (jsonStringDate: string): string {
  if (jsonStringDate) {
    const date = parseJSON(jsonStringDate)
    return format(date, 'HH:mm')
  }
  return ''
}

export function getDayAfter (startAt: Date, originalEndAt: Date): Date {
  const newEndAt = new Date(startAt)
  newEndAt.setHours(
    originalEndAt.getHours(),
    originalEndAt.getMinutes(),
  )
  return addDays(newEndAt, 1)
}
