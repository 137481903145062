/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import { CarBrand } from '@/api/interfaces/carbrand'
import http from '@/plugins/axios'

export interface CarBrandState {
  list: Array<CarBrand>
  totalItems: number
}

export function newCarBrand (): CarBrand {
  return {
      '@id': '',
      brand: '',
  }
}

@Module({ name: 'CarBrand', namespaced: true })
class CarBrandModule extends VuexModule implements CarBrandState {
  public list: Array<CarBrand> = []
  public totalItems = 0

  @Mutation
  public setList (list: Array<CarBrand>): void {
    this.list = list
  }

  @Mutation
  public addBrand (brand: CarBrand): void {
    this.list.push(brand)
    this.totalItems++
  }

  @Mutation
  public setTotalItems (total: number): void {
    this.totalItems = total
  }

  @Action({ rawError: true })
  public async load (): Promise<Array<CarBrand>> {
    const response = await http.get('/api/car_brands?pagination=false')
    this.context.commit('setList', response.data['hydra:member'])
    this.context.commit('setTotalItems', response.data['hydra:totalItems'])
    return response.data['hydra:member'] as Array<CarBrand>
  }

  @Action({ rawError: true })
  public async save (brand: string): Promise<CarBrand> {
    const brandObject = newCarBrand()
    brandObject.brand = brand
    const response = await http.post('/api/car_brands', brandObject)
    this.context.commit('addBrand', response.data)
    return response.data as CarBrand
  }
}

export default CarBrandModule
