/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { User } from '@/api/interfaces/user'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { getField, updateField } from 'vuex-map-fields'
import http from '@/plugins/axios'
import { cloneObjectReplaceSubObjectByIRI } from '@/store/api-plateform-utils'

export interface UserFormState {
  user: User
  isChanged: boolean
  isSubmitted: boolean
}

export function newUser (): User {
  return {
    '@id': '',
    email: '',
    firstName: '',
    password: '',
    roles: '[]',
    lastName: '',
    userGroups: [],
  }
}

@Module({ name: 'userForm', namespaced: true })
class UserFormModule extends VuexModule implements UserFormState {
  user: User = newUser()
  isChanged = false
  isSubmitted = false

  @Mutation
  public setUser (user: User): void {
    this.user = user
    this.isChanged = false
  }

  @Mutation
  public setIsSubmitted (submitted: boolean): void {
    this.isSubmitted = submitted
  }

  @Mutation
  updateField (options: {path: string; value: unknown}) {
    this.isChanged = true
    return updateField(this, options)
  }

  get getField () {
    return getField(this)
  }

  @Action({ rawError: true })
  public async loadById (id: number): Promise<User> {
    const response = await http.get(`/api/users/${id}`)
    const user = response.data as User
    this.context.commit('setUser', user)
    return user
  }

  @Action({ rawError: true })
  public async save (): Promise<User> {
    this.context.commit('setIsSubmitted', true)
    try {
      const userToPost: User = cloneObjectReplaceSubObjectByIRI(this.user)
      if (userToPost['@id']) {
        const response = await http.put(userToPost['@id'], userToPost)
        this.context.commit('setUser', response.data)
      } else {
        const response = await http.post<User>('/api/users', userToPost)
        this.context.commit('setUser', response.data)
      }
    } finally {
      this.context.commit('setIsSubmitted', false)
    }
    return this.user
  }

  @Action({ rawError: true })
  public async reset (): Promise<User> {
    const user:User = newUser()
    this.context.commit('setUser', user)
    this.context.commit('setIsSubmitted', false)
    return user
  }
}

export default UserFormModule
